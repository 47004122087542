import React, { useEffect } from "react"
import Layout from "components/layout"
import { navigate } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { connect } from "react-redux"

const TestCdrSandboxSuccess = (props) => {

  const fragment = props.location.hash

//   useEffect(() => {
//     setTimeout(() => {
//       if (typeof window !== 'undefined') {
//         const jsonInput = {
//           surveyId: window.sessionStorage.getItem("surveyId"),
//           fragment,
//         }
//         API.getAuthenticatedAccountData(jsonInput, jsonResponse => {
//           window.sessionStorage.setItem("refreshToken", jsonResponse.refreshToken)
//           jsonResponse.input.surveyId = window.sessionStorage.getItem("surveyId")
//           navigate("/credit-cards/your-current-deal", {
//             state: {
//               jsonInput: jsonResponse.input,
//               responseJson: jsonResponse.output,
//               progress: 6,
//               prevPath: props.path,
//               gofc: true,
//             },
//           })
//         })
//       }
//     }, 2000)
//     return () => { }
//   }, [])

  return (
    <Layout>
      <div className="page-container">
        <div className="survey-container">
          {/* {fragment ? (
            <div className={classes.header} style={{ marginTop: "30px" }}>
              <LazyLoadImage src="/images/icon_green_tick.png" style={{width: "25px", height: "20px"}} alt="" />
              <span className={classes.headerText} style={{ height: "20px", fontFamily: "Arboria-Bold, sans-serif", fontSize: "22px" }}>
                Success
              </span>
            </div>
          ) : (
            <div>
              Error
            </div>
          )} */}
          success
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = () => {
  return {
    surveyId: typeof window !== 'undefined' ? window.sessionStorage.getItem("surveyId") : "123456789",
  }
}

export default connect(mapStateToProps)(TestCdrSandboxSuccess)